<template>
  <div class="main-wrapper about-us">
    <!-- <LoginModal :loginModal="loginModal" @init="init()"></LoginModal> -->

    <!--訂單查詢-->
    <section class="py-4">
      <div class="container">
        <!--注意事項-->
        <div class="row">
          <div class="col-sm-12 col-xs-12 order-md-1">
            <div
              role="alert"
              class="alert alert-success alert-dismissible fade show font-size-18"
            >
              <p class="text-danger font-size-24">
                <i class="far fa-file-alt fa-2x" aria-hidden="true"></i
                >&nbsp;&nbsp;注意事項：
              </p>
              <span class="text-danger"
                >請注意：卓越盃不會主動以電話通知您成績或得獎資訊！</span
              >若接獲不明電話，請勿提供任何個人資料及金融資訊，有任何競賽問題，請主動撥打卓越盃「客服專線02-86651745」洽詢或至「聯絡我們」留言。
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--訂單列表-->
    <section class="pb-4" v-if="isLogin && !IsDetail">
      <div class="container">
        <div class="row justify-content-center">
          <div class="table-responsive tabw">
            <table class="table table-bordered text-center mb-0">
              <!--訂單列表標題-->
              <thead>
                <tr>
                  <th
                    class="bg-primary text-white text-uppercase font-size-18"
                    scope="col"
                  >
                    訂單編號
                  </th>
                  <th class="bg-success text-white text-uppercase" scope="col">
                    訂單日期
                  </th>
                  <th class="bg-danger text-white text-uppercase" scope="col">
                    報考人數
                  </th>
                  <th class="bg-pink text-white text-uppercase" scope="col">付款方式</th>
                  <th class="bg-info text-white text-uppercase" scope="col">付款金額</th>
                  <th class="bg-purple text-white text-uppercase" scope="col">
                    訂單狀態
                  </th>
                  <th class="bg-primary text-white text-uppercase" scope="col"></th>
                </tr>
              </thead>
              <!--資料清單-->
              <tbody>
                <tr v-for="item in orderList" :key="item.SN">
                  <td class="align-middle text-muted font-weight-medium">
                    {{ item.SN }}
                  </td>
                  <td class="py-4 text-muted">
                    {{ dateFormat(item.OrderDate) }}
                  </td>
                  <td class="text-muted">
                    {{ item.PostCode }}
                  </td>
                  <td class="text-muted">
                    {{
                      dictPaidType[item.PaidType] == null
                        ? dictPaidType["C1"]
                        : dictPaidType[item.PaidType]
                    }}
                  </td>
                  <td class="text-muted">NT$ {{ item.ChargeTotal }}</td>
                  <td class="text-muted">
                    {{
                      dictPaidStatus[item.Status] == null
                        ? "處理中"
                        : dictPaidStatus[item.Status]
                    }}
                    <div
                      v-if="
                        (item.Status == 96 || item.Status == 94 || item.Status == 89) &&
                        item.RefundPaidTotal != null &&
                        item.RefundPaidTotal != 0
                      "
                    >
                      (NT${{ item.RefundPaidTotal }})
                    </div>
                  </td>
                  <td class="text-muted">
                    <button
                      class="btn btn-success mb-2"
                      @click="funcationOrderDetail(item.SN)"
                    >
                      查看明細
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <!--訂單明細資訊-->
    <section class="pb-6" v-if="IsDetail">
      <!--<section class="pb-6">-->
      <div class="container">
        <div class="section-title justify-content-center mb-4 mb-md-8">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">訂單資訊</h2>
          <span class="shape shape-right bg-info"></span>
        </div>
        <!--訂單編號/訂單日期資訊-->
        <div class="row">
          <div class="col-md-6 col-lg-12">
            <h4 class="text-capitalize font-weight-bold mb-4">
              <span class="text-danger">訂單編號: </span>
              <span class="text-dark font-size-24">{{ order.No }} </span>
              <h4 class="text-capitalize font-weight-bold mb-4 float-end">
                訂單日期:
                <span class="text-dark font-size-24">{{
                  dateFormat(order.OrderDate)
                }}</span>
              </h4>
            </h4>
            <!--資料清單-->
            <div class="table-responsive tabw">
              <table class="table table-bordered text-center mb-0">
                <thead>
                  <tr>
                    <!--資料清單標題行-->
                    <th
                      class="col-1 bg-primary text-white text-uppercase font-size-18"
                      scope="col"
                    >
                      考生姓名
                    </th>
                    <th class="col-2 bg-success text-white text-uppercase" scope="col">
                      報考項目
                    </th>
                    <th class="col-1 bg-danger text-white text-uppercase" scope="col">
                      報考年級
                    </th>
                    <th class="col-4 bg-purple text-white text-uppercase" scope="col">
                      <b-form-checkbox v-model="orderDetail.all">
                        訂購項目
                        <a
                          href="/#/Notice/FeeRefundPolicy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style="color: blue; margin-left: 5px"
                          >退費辦法</a
                        >
                      </b-form-checkbox>
                    </th>
                    <th class="col-1 bg-pink text-white text-uppercase" scope="col">
                      價格
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!--無資料-->
                  <tr v-if="orderDetail == null">
                    <td colspan="7">尚未有資料</td>
                  </tr>
                  <!--資料清單-->
                  <tr v-for="item in orderDetail" :key="item.OrderId">
                    <!--資料清單-考生姓名-->
                    <td class="align-middle text-muted font-weight-medium">
                      {{ item.StudentName }}
                    </td>
                    <!--資料清單-報考項目-->
                    <td class="py-4 text-muted">
                      {{ item.ExamItem }}
                      <!-- {{ getAllProductNames(item.ProductDetails) }} -->
                      <span
                        class="text-danger"
                        v-if="
                          item.addBuyExamSorderNo != '' &&
                          item.addBuyExamSorderNo != null &&
                          item.ExamGrade != 1
                        "
                      >
                        <br />
                        加考閱讀競賽(訂單編號:
                        <a
                          href="#my-mark"
                          @click="funcationOrderDetail(item.addBuyExamSorderNo)"
                        >
                          {{ item.addBuyExamSorderNo }} </a
                        >)
                      </span>
                    </td>
                    <!--資料清單-報考年級-->
                    <td class="text-muted">
                      {{ item.ExamGrade }}
                    </td>
                    <!--資料清單-訂購項目-->
                    <td class="text-muted text-sm">
                      <div
                        v-for="(PDitem, index) in item.ProductDetails"
                        :key="PDitem.Id + PDitem.PartNo"
                      >
                        <div v-if="PDitem.Price != 0">
                          <!-- {{ PDitem.Id }}-{{ PDitem.PartNo }} -->
                          <input
                            type="checkbox"
                            :id="`checkbox-${PDitem.Id}-${PDitem.PartNo}`"
                            v-model="PDitem.checked"
                            class="buy"
                            v-if="PDitem.Status == 3 && PDitem.AllowRefund"
                          />
                          <label :for="`checkbox-${PDitem.Id}-${PDitem.PartNo}`">
                            &nbsp;{{ index + 1 }}. {{ PDitem.Name }}(NT$
                            {{ PDitem.Price }})</label
                          >
                          <label
                            :for="`checkbox-${PDitem.Id}-${PDitem.PartNo}`"
                            v-if="PDitem.Status == 93"
                            class="text-danger"
                            >-退款申請中</label
                          >
                          <label
                            :for="`checkbox-${PDitem.Id}-${PDitem.PartNo}`"
                            v-if="PDitem.Status == 96"
                            class="text-danger"
                            >-退款完成</label
                          >
                        </div>
                      </div>
                    </td>
                    <!--資料清單-價格-->
                    <td class="text-muted">
                      NT$ {{ calculateTotalPrice(item.ProductDetails) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--ATM退費欄位及退費原因模版-->
          <template>
            <section class="pb-6"></section>
            <div>
              <div class="row mb-12" v-if="order.PaidType == 'ATM'">
                <div class="col-md-12">
                  <!-- 银行/分支单位 -->
                  <label>
                    <h4 class="text-capitalize font-weight-bold text-danger">
                      銀行/分支單位(必填)
                    </h4>
                  </label>
                  <b-form-input
                    v-model="refund.BankCode"
                    list="BankCode-list"
                    @keyup="GetBankCodeSelected()"
                    placeholder="可輸入關鍵字查詢銀行/分支單位"
                  ></b-form-input>
                  <b-form-datalist id="BankCode-list">
                    <option v-for="item in bankCodeOptions" :key="item.EXCODE">
                      {{ item.NAME }}
                    </option>
                  </b-form-datalist>
                </div>
              </div>
              <div class="row mb-12" v-if="order.PaidType == 'ATM'">
                <div class="col-md-6">
                  <!-- 存摺帳號 -->
                  <label>
                    <h4 class="text-capitalize font-weight-bold text-danger">
                      存摺帳號(必填)
                    </h4>
                  </label>
                  <b-form-input v-model="refund.BankAccount"></b-form-input>
                </div>
                <div class="col-md-6">
                  <!-- 户名 -->
                  <label>
                    <h4 class="text-capitalize font-weight-bold text-danger">
                      戶名(必填)
                    </h4>
                  </label>
                  <b-form-input v-model="refund.BankAccountName"></b-form-input>
                </div>
              </div>
            </div>
            <section class="pb-6"></section>
            <div>
              <!-- 退費原因 -->
              <label>
                <h4 class="text-capitalize font-weight-bold text-danger">退費原因</h4>
              </label>
              <b-form-input
                v-model="refund.Note"
                placeholder="請填寫欲退費的項目及原因"
                :maxlength="200"
              ></b-form-input>
            </div>
          </template>

          <!-- 回查詢頁及提交按钮 -->
          <section class="pb-6">
            <div class="container d-flex justify-content-center mt-7">
              <button class="btn btn-danger text-uppercase" @click="back">
                回查詢頁
              </button>
              <button
                v-b-modal.modal-refund
                class="btn btn-primary text-uppercase pull-right"
                @click="Refund()"
              >
                送出
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { checkLogin } from "@/utils/auth";
export default {
  components: {},
  name: "RefundPage",
  data() {
    return {
      isLogin: false,
      memberId: null,
      loginName: "",
      loginModal: {
        show: true,
        url: "/",
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        isTimeover: false,
      },
      IsDetail: false,
      order: {},
      orderDetail: {},
      ProductDetail: {},
      orderList: null,
      expandStudent: false,
      orderNo: "",
      studentName: "",
      examItem: "",
      examGrade: "",
      paidType: "",
      paidStatus: 0,
      billTaxY: "",
      billTaxN: "",
      //付款類別
      dictPaidType: { C1: "信用卡一次付清", ATM: "ATM 匯款" },
      //訂單狀態
      dictPaidStatus: {
        0: "未完成付款",
        1: "未完成付款",
        2: "未完成付款",
        3: "付款完成",
        93: "退款申請中",
        96: "退款完成",
        98: "付款失敗，已取消",
        99: "取消",
      },
      //退款明細
      refund: {},
      //是否開放退費
      openRefund: false,
      //銀行單位
      bankCodeOptionsAll: {},
      bankCodeOptions: [],
      //訂單明細,訂單商品記錄
      apiOrderDetail: (data) => this.userRequest.post("KC/OrderDetail", data),
      //卓越盃維護設定
      apiYearData: (data) => this.userRequest.post("KC/YearData", data),
    };
  },
  computed: {},

  mounted() {
    this.init();
  },
  watch: {
    "orderDetail.all"(val) {
      console.log("🚀 ~ val:", val);

      for (const item of this.orderDetail) {
        for (const PDitem of item.ProductDetails) {
          if (PDitem.Status == 3) {
            PDitem.checked = val;
          }
        }
      }
    },
  },
  methods: {
    // test
    async sendInfo(id) {
      await new Promise((r) => setTimeout(r, 300));
      this.selectedUserId = id;
    },
    // test
    async init() {
      checkLogin();
      this.isLogin = sessionStorage.getItem("isLogin") === "true";
      if (this.isLogin) {
        this.memberId = sessionStorage.getItem("MemberId");
        this.loginName = sessionStorage.getItem("LoginName");
      }

      this.getRefundIsOpen();

      this.refuncDetailStatus = 0;

      //await this.GetOrderDetail();

      await this.getBankCode();

      if (this.memberId != null && !this.IsDetail) {
        try {
          let data = {
            TYPE: "SELECT_BY_MEMBER",
            Order_SELECT: {
              Id: this.memberId,
            },
          };
          let res = await this.userRequest.post("KC/Order", data);
          let json = JSON.parse(res.data);

          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (!jsonData) {
              this.orderList = null;
              return;
            }

            // 过滤订单列表，只包含状态(int)
            //3: "付款完成",93: "退款申請中"
            const validStatuses = [3, 93];

            this.orderList = jsonData.outData.filter((order) =>
              validStatuses.includes(order.Status)
            );

            //alert(this.memberId);
          } else {
            // 如果响应的 Status 是 false, 处理失败情况
            console.error("Error fetching data:", json.Message);
            alert("Failed to fetch data: " + json.Message);
          }
        } catch (err) {
          console.error("Error during API request:", err);
          alert("An error occurred: " + err.message);
        }
      }
    },
    //考生訂單明細資料
    funcationOrderDetail(orderNo) {
      let json = {
        TYPE: "SELECT_BY_IDNO_LIST_FOR_Refund",
        OrderDetail_SELECT: {
          OrderId: orderNo,
        },
      };
      this.FunctionToken(this.GetOrderDetail, json);
    },
    GetOrderDetail(data) {
      this.apiOrderDetail(data).then((res) => {
        let json = JSON.parse(res.data);
        if (json.Status) {
          let jsonData = JSON.parse(json.Data);
          if (!jsonData) {
            alert("無符合資料");
          } else {
            this.order = jsonData.outData;

            // 过滤订单列表，只包含状态(int)

            this.orderDetail = jsonData.outDataDetail;
            console.log("🚀 ~ this.apiOrderDetail ~ orderDetail:", this.orderDetail);
            this.ProductDetail = this.orderDetail[0].ProductDetails?.filter(
              (detail) => detail.Price !== 0
            );

            // alert("Sample data:" + this.ProductDetail[0].ProductDetailStatus); // Debugging

            // 对过滤后的订单详情进行排序
            this.orderDetail.sort((a, b) => a.No.localeCompare(b.No));
            if (this.order.BillSN != null) {
              this.billTaxY = "";
              this.billTaxN = "";

              try {
                var billTax = this.order.BillSN.split(",");
                if (billTax[0] != "") {
                  this.billTaxY = billTax[0];
                }
                if (billTax[1] != "") {
                  this.billTaxN = billTax[1];
                }
              } catch (e) {
                this.billTaxY = "";
                this.billTaxN = "";
              }
            }

            this.IsDetail = true;
          }
        }

        // const target = document.querySelector("#my-mark");
        // target.scrollIntoView({ behavior: "smooth" });
      });
    },
    expand() {
      this.expandStudent = !this.expandStudent;
    },
    back() {
      this.order = null;
      this.orderDetail = null;
      this.ProductDetail = null;
      this.IsDetail = false;
    },

    collectCheckedItems() {
      // 遍历 orderDetail 来构建数据结构
      console.log("thos.orderDetail", this.orderDetail);
      const checkedOrderDetails = this.orderDetail
        .filter((item) => item.ProductDetails.some((PDitem) => PDitem.checked))
        .map((item) => {
          console.log("🚀 ~ .map ~ item:", item);

          return {
            id: item.OrderId, // 假设每个 orderDetail 有一个唯一的 ID
            status: 93,
            RefundPaidItem: this.refund.Note,
            BankCode: this.refund.BankCode,
            BankAccount: this.refund.BankAccount,
            BankAccountName: this.refund.BankAccountName,
            ProductDetails: item.ProductDetails.filter((PDitem) => PDitem.checked).map(
              (PDitem) => {
                return {
                  id: PDitem.Id, // 假设每个 productDetail 有一个唯一的 ID
                  status: 93,
                  PartNo: PDitem.PartNo,
                  OriginPartNo: PDitem.OriginPartNo,
                  OriginId: PDitem.OriginId,
                  // 其他你需要提交的字段
                };
              }
            ),
          };
        });
      console.log("🚀 ~ collectCheckedItems ~ checkedOrderDetails:", checkedOrderDetails);

      return {
        TYPE: "REFUND",
        orderId: this.order.No, // 假设顶层 order 对象有一个 No 字段作为 ID
        status: 93,
        orderDetails: checkedOrderDetails,
      };
    },
    //按下退款送出
    async Refund() {
      const dataToSubmit = this.collectCheckedItems();
      console.log("🚀 ~ Refund ~ dataToSubmit:", dataToSubmit);
      let error = 0;
      if (dataToSubmit.orderDetails.length === 0) {
        alert("請至少勾選一個項目");
        return;
      } else {
        if (this.refund.Note == "" || this.refund.Note === undefined) {
          alert("請填寫欲退費的項目及原因");
        } else if (this.order.PaidType == "ATM") {
          if (this.refund.BankCode == "" || this.refund.BankCode === undefined) {
            alert("請填寫欲退回費用的銀行/分支單位");
            error = 1;
          } else if (
            this.refund.BankAccount == "" ||
            this.refund.BankAccount === undefined
          ) {
            alert("請填寫欲退回費用的存摺帳號");
            error = 1;
          } else if (
            this.refund.BankAccountName == "" ||
            this.refund.BankAccountName === undefined
          ) {
            alert("請填寫欲退回費用的戶名");
            error = 1;
          }
        }

        if (error == 1) return;

        let refundCheckResult = await this.userRequest.post("KC/ProductDetail_Refund", {
          TYPE: "CHECK_REFUND",
          orderId: dataToSubmit.orderId,
          orderDetails: dataToSubmit.orderDetails,
        });
        console.log("🚀 ~ Refund ~ refundCheckResult:", refundCheckResult);

        if (refundCheckResult.Status == false) {
          alert(refundCheckResult.Message);
          return;
        }

        let refundCheckJson = JSON.parse(refundCheckResult.data);
        console.log("🚀 ~ Refund ~ refundCheckJson:", refundCheckJson);
        let refundCheckData = JSON.parse(refundCheckJson.Data);
        let refundCheckDataMessage = JSON.parse(refundCheckData.Data);

        if (refundCheckData.Status == false) {
          if (refundCheckDataMessage.confirm == true) {
            if (!confirm(refundCheckDataMessage.message)) {
              return;
            }
          } else {
            alert(refundCheckDataMessage.message);
            return;
          }
        } else {
          if (!confirm("確定要退費嗎?")) {
            return;
          }
        }

        try {
          let res = await this.userRequest.post("KC/ProductDetail_Refund", dataToSubmit);

          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (!jsonData) {
              alert("退費並未成功申請，請稍候再重新申請作業。");
            } else {
              this.refund.Note = "";
              this.order = jsonData.outData;
              this.orderDetail = jsonData.outDataDetail;
              //this.ProductDetail = this.orderDetail.ProductDetails;

              alert(
                "退費已成功申請，已收到您的取消報名訊息，詳細結果請至卓越盃官網查詢，相關退書事宜請依卓越盃官網說明辦理，若有問題歡迎隨時與我們聯繫！"
              );

              this.back();
            }
          } else {
            alert("退費並未成功申請，請稍候再重新申請作業。");
          }
        } catch (err) {
          console.log(err);
          alert(err);
        }
      }
    },
    //取得退款開始日
    getRefundIsOpen() {
      let json = {
        TYPE: "FRONT_REFUND_ISOPEN",
      };
      this.FunctionToken(this.RefundIsOpen, json);
    },

    RefundIsOpen(data) {
      this.apiYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          if (jsonRdata.Status) {
            this.openRefund = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //取得銀行單位
    async getBankCode() {
      try {
        let data = {
          TYPE: "SELECT_BANK_CODE",
          Setting_SELECT: {
            Id: 1,
          },
        };

        let res = await this.userRequest.post("KC/Setting", data);
        let json = JSON.parse(res.data);

        if (json.Status) {
          let jsonData = JSON.parse(json.Data);
          this.bankCodeOptionsAll = jsonData;
        }
      } catch (err) {
        console.log(err);
      }
    },
    RefundChangeAll() {
      for (const item of this.orderDetail) {
        for (const PDitem of item.ProductDetails) {
          if (PDitem.Status == 3) {
            PDitem.checked = this.orderDetail.all;
          }
        }
      }
    },
    GetBankCodeSelected() {
      this.bankCodeOptions = this.bankCodeOptionsAll.filter((x) =>
        x.NAME.includes(this.refund.BankCode)
      );
    },
    // 计算总金额
    calculateTotalPrice(productDetails) {
      if (!productDetails) return 0;
      return productDetails.reduce((total, item) => {
        return item.Price > 0 ? total + item.Price : total;
      }, 0);
    },

    // 获取所有产品名称
    getAllProductNames(productDetails) {
      console.log("productDetails", productDetails);
      if (!productDetails) return "";
      return productDetails
        .filter(
          (item) => item.Price > 100 && item.Price < 1000 && item.Name.includes("競賽")
        )
        .map((item) => item.Name)
        .join("+ ");
    },
  },
};
</script>
<style scoped>
.text-sm {
  font-size: 0.875rem; /* 14px */
}

.text-md {
  font-size: 1rem; /* 16px */
}
</style>
