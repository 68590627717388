<template>
  <div>
    <header class="header" id="pageTop">
      <Header />
      <NavBar />
    </header>
    <KeepAlive include="home-component">
      <router-view></router-view>
    </KeepAlive>
    <Footer />
  </div>
</template>

<script>

import Header from './components/Header.vue'
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header, NavBar, Footer
  },
  created() {
    document.title = "康軒卓越盃全國競賽 ";
  }
}
</script>