<script></script>
<template>
  <div >
    <!--第二顆按鈕模板 tabs: [{ id: 2, name: '退費辦法' }, ] -->
    <section >

        <div class="row pt-4">
          <div class="section-title justify-content-center mb-3">
            <span class="shape shape-left bg-info"></span>
            <h2 class="text-danger">退費方式</h2>
            <span class="shape shape-right bg-info"></span>
          </div>
          <div class="col-sm-12 col-xs-12">
            <div class="mb-4">
              <ol class="mb-4">
                <h5 class=" font-size-20">一、	網路報名考生請於詳閱退費辦法後，點選以下【退費】按鈕，登入會員後申請退費</h5>
              </ol>
              <ol class="mb-4">
                <h5 class=" font-size-20">二、	紙本報名考生請於詳閱退費辦法後，撥打客服專線，以通訊方式申請退費</h5>
                <ul>
                  <ul class="mb-4 font-size-20">
                    <li>競賽退費客服專線 : 02-8665-1745</li>
                    <li>線上課程退費客服專線 : 02-8665-1234</li>
                  </ul>
                </ul>
              </ol>
              <ol class="mb-4">
                <h5 class=" font-size-20">三、	如報名競賽及加購商品時，使用信用卡付款，退費款項將直接退還至您的信用卡帳戶；如報名競賽及加購商品時，使用ATM付款，請於申請退費時提供欲退款之本人帳戶，退費款項將退還至您所提供的帳戶。</h5>
              </ol>
              <ol class="mb-4">
                <h5 class=" font-size-20">四、	退費處理時間約為三十個工作天左右；資料填寫有誤或不全者，將影響退費時間。</h5>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="section-title justify-content-center mb-3">
            <span class="shape shape-left bg-info"></span>
            <h2 class="text-danger">報名費退費辦法</h2>
            <span class="shape shape-right bg-info"></span>
          </div>
          <div class="col-sm-12 col-xs-12">
            <div class="mb-4">
              <ol class="mb-4">
                <h5 class=" font-size-20">
                  本競賽之退費基準如下，報名者申請退費後，由主辦單位視各競賽報名階段，每位退費考生扣除行政作業費及題本費後將退款至所提供之本人帳戶：
                </h5>

                <h5 class=" font-size-20">一、	於競賽報名第一階段申請退費者：</h5>
                <ul>
                  <ul>
                    <li class="mb-4 font-size-20">
                      自個人報名繳費成功起至10/4競賽報名截止日止，欲申請退賽及退還報名費者，主辦單位將於扣除<span class="text-danger">「行政作業費NT$100」</span>及<span class="text-danger">「題本費」</span>後，將剩餘費用進行退費。
                      <br />
                      註 : 報名數學競賽者，題本費為NT$280；加報閱讀競賽者，題本費為NT$160；報名數學+閱讀競賽者，題本費為NT$ 440。
                    </li>
                  </ul>
                </ul>

                <h5 class=" font-size-20">二、	於競賽報名第二階段申請退費者：</h5>
                <ul>
                  <ul class="mb-4 font-size-20">
                    <li class=" font-size-20">
                      自10/5日起至10/31止，欲申請退賽及退還報名費者，退費將於扣除<span class="text-danger">「行政作業費NT$200」</span>
                      及<span class="text-danger">「題本費」</span>後，將剩餘費用進行退費。
                      <br />
                      註 : 報名數學競賽者，題本費為NT$280；加報閱讀競賽者，題本費為NT$160；報名數學+閱讀競賽者，題本費為NT$ 440。
                    </li>
                  </ul>
                </ul>
                <h5 class=" font-size-20">三、	於競賽報名第三階段申請退費者：</h5>
                <ul>
                  <ul class="mb-4 font-size-20">
                    <li class=" font-size-20">
                      自11/1起至11/14止，因已進入競賽物資準備作業，退費將扣除<span class="text-danger">「報名費50%」</span>
                      及<span class="text-danger">「題本費」</span>後，將剩餘費用進行退費。
                      <br />
                      註 : 報名數學競賽者，題本費為NT$280；加報閱讀競賽者，題本費為NT$160；報名數學+閱讀競賽者，題本費為NT$ 440。
                    </li>
                  </ul>
                </ul>


                <h5 class=" font-size-20">四、申請退費截止日為11/14，自11/15起申請退費者，恕不受理。</h5>

                <p class="text-danger font-size-20">
                  ※ 如因報名者發生重大傷病或其直系親屬因故傷亡時，應檢附書面證明資料申請退費，主辦單位於扣除「題本費」後將剩餘費用進行退費。
                  <br />
                  ※ 如因地震、颱風、傳染病、罷工、遊行等重大事件因素造成競賽無法如期舉行，主辦單位得另行公布相關處理辦法。
                </p>
              </ol>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="section-title justify-content-center mb-3">
            <span class="shape shape-left bg-info"></span>
            <h2 class="text-danger">加購品退費辦法</h2>
            <span class="shape shape-right bg-info"></span>
          </div>
          <div class="col-sm-12 col-xs-12">
            <div class="mb-6">
              <ul class="mb-6 font-size-20">
                <h5 class=" font-size-20">一、	閱讀高手退貨(費)辦法</h5>

                <ol>
                  <li class=" font-size-20">
                    報名競賽加購「閱讀高手」者，加購書籍享有十四天猶豫期（含例假日），於<span class="text-danger">付款成功次日起十四天內</span>可申請退貨，並請寄回加購書籍。
                  </li>
                  <li class=" font-size-20">
                    因猶豫期非試用期，逾期退回商品必須為全新狀態（不可簽名、註記、折頁等），否則恕不接受退貨。
                  </li>
                  <li class=" font-size-20">
                    主辦單位於收到退貨商品後，確認書籍狀態無瑕疵，即會辦理退費作業。
                  </li>
                  <li class=" font-size-20">
                    若未申請退貨或告知，即自行寄回欲退加購書籍，若商品遺失，主辦單位將不負擔相關損失責任。
                  </li>
                </ol>
              </ul>
              <ul class="mb-6 font-size-20">
                <h5 class=" font-size-20">二、	衝刺包、考後真人解題攻略退貨(費)辦法</h5>
                <ol>
                  <li class=" font-size-20">
                    報名競賽加購數學或閱讀「衝刺包」者（含「衝刺包」及附贈之「考後真人解題攻略」），享有十四天猶豫期（含例假日），即<span class="text-danger">自付款成功次日起十四天內（均含例假日）</span>，可申請退費；自付款成功次日起十五天後（即十四天猶豫期過後），不論是否開通課程，恕不接受退費申請。
                  </li>
                  <li class=" font-size-20">
                    單純加購數學或閱讀「考後真人解題攻略」者，因「考後真人解題攻略」為預購課程，故於課程上線後享有十四天猶豫期（含例假日），即<span class="text-danger">自付款成功次日起至課程上線日起十四天內（含例假日）</span>，可申請退費；如自課程上線次日起十五天後（即十四天猶豫期過後），不論是否開通課程，恕不接受退費申請。
                  </li>
                  <li class=" font-size-20">
                    主辦單位於確認退貨申請後，即會辦理退費作業。
                  </li>
                </ol>
              </ul>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FeeRefundPolicy"
};
</script>
